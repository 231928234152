import { useFetcher } from '@remix-run/react';

function getFetcherNavigation<T = unknown>(navigation: ReturnType<typeof useFetcher<T>>) {
	const isGetRequest = navigation.formMethod !== undefined && navigation.formMethod === 'GET';

	return {
		isIdle: navigation.state === 'idle',
		/**
		 * Actions
		 */
		isSubmission: (
			navigation.state === 'submitting'
			&& !isGetRequest
		),

		isRefresh: (
			navigation.state === 'loading'
			&& !isGetRequest
			&& !nullOrUndefined(navigation.data) // Refresh requires data to be present
		),

		isSubmissionRedirect: (
			navigation.state === 'loading'
			&& !isGetRequest
			&& nullOrUndefined(navigation.data) // Redirects will have no data
		),

		/**
		 * Loaders
		 */
		isLoading: (
			// Old fetcher.type === "normalLoad"
			navigation.state === 'loading'
			&& nullOrUndefined(navigation.formMethod)
		),

		isLoadingRedirect: (
			navigation.state === 'loading'
			&& nullOrUndefined(navigation.data) // Redirects will have no data
		),

		isLoadSubmission: (
			// Old fetcher.type === "loaderSubmission"
			navigation.formMethod === 'GET'
		),

		/**
		 * Catch all
		 */
		isProcessing: (
			navigation.state !== 'idle'
		),
	};
}

function nullOrUndefined(value: any) {
	return value === null || value === undefined;
}

export { getFetcherNavigation as default, getFetcherNavigation };
